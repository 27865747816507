import React, { useId } from "react";

import { InputField, type InputFieldProps } from "@jasperlabs/jux-next";
import { Field, type FieldProps } from "formik";

export type TextInputFieldProps = Omit<InputFieldProps, "id"> & {
	name: string;
	className?: string;
	id?: string;
};

/**
 * Must be used within a `<Formik>` context provider
 */
const FormikInputField = ({
	name,
	onChange,
	onBlur,
	className,
	id,
	...props
}: TextInputFieldProps) => {
	const generatedId = useId();

	return (
		<Field name={name} className={className}>
			{({ field, meta }: FieldProps) => {
				const isInvalid = Boolean(meta.touched && meta.error);

				const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
					field.onChange(name)(e);
					onChange?.(e);
				};

				const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
					field.onBlur(name)(e);
					onBlur?.(e);
				};
				return (
					<InputField
						id={id ?? generatedId}
						onChange={handleChange}
						onBlur={handleBlur}
						value={field.value}
						hasError={isInvalid}
						error={meta.error}
						{...props}
					/>
				);
			}}
		</Field>
	);
};

export default FormikInputField;
