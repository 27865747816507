import React from "react";

const SvgDropdownIndicator = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.64 13.376l4.355-4.356 4.37 4.344 2.356-2.357-6.732-6.694-6.714 6.713 2.364 2.35zm8.72 13.248l-4.355 4.356-4.37-4.343-2.356 2.357 6.733 6.693 6.713-6.713-2.364-2.35z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgDropdownIndicator;
