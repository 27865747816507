export const getNestedObjectByKey = <T extends Record<string, any>>(
	nestedObj: Record<string, any>,
	groupKey?: string,
): T | undefined => {
	if (!groupKey) {
		return nestedObj as T;
	}

	const pathArr = groupKey.split(".");
	return pathArr.reduce(
		(obj, key) => (obj ? obj[key] : undefined),
		nestedObj,
	) as T | undefined;
};
