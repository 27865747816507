import * as R from "ramda";
import { DeepPartial } from "@jasperlabs/types";

export const getNestedObjectByKey = (
	nestedObj: any,
	groupKey?: string,
): any => {
	if (!groupKey || groupKey === "") {
		return nestedObj;
	}

	const pathArr = groupKey.split(".");
	return pathArr.reduce((obj, key) => (obj ? obj[key] : undefined), nestedObj);
};

export const sanitizeInput = <T>(input: T): T =>
	// @ts-ignore
	R.mapObjIndexed((i) => (R.isEmpty(i) ? null : i), input);

export function filterChangedFields<T extends Record<string, any>>(
	values: T,
	initialValues: T,
): DeepPartial<T> {
	return Object.entries(values).reduce<DeepPartial<T>>((acc, [key, value]) => {
		if (typeof value === "object" && value !== null && !Array.isArray(value)) {
			const nestedFields = filterChangedFields(value, initialValues[key]);

			if (Object.keys(nestedFields).length > 0) {
				return {
					...acc,
					[key]: nestedFields,
				};
			}

			return acc;
		}

		const hasChanged = initialValues[key] !== value;

		if (hasChanged) {
			return {
				...acc,
				[key]: value,
			};
		}

		return acc;
	}, {} as DeepPartial<T>);
}
