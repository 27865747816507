import React, { forwardRef } from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import clsx from "clsx";

import { IconDropdownIndicator } from "../../icon";
import { FormField, FormFieldProps } from "../FormField";

const NULL_VALUE = "__null__";

// Overrides value & onValueChange props to allow null value
export type SelectProps = Omit<
	SelectPrimitive.SelectProps,
	"value" | "onValueChange"
> & {
	id?: string;
	className?: string;
	disabled?: boolean;
	readOnly?: boolean;
	hasError?: boolean;
	placeholder?: React.ReactNode;
	"data-testid"?: string;
	onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
	formatSelectedValue?: (value: string | null) => React.ReactNode;
	onValueChange?: (value: string | null) => void;
	value: string | null;
};
// Todo: Debug why down and up arrows trigger ref undefined error.
const Select = forwardRef<HTMLButtonElement, SelectProps>(
	(
		{
			id,
			className,
			disabled = false,
			readOnly = false,
			hasError = false,
			placeholder,
			children,
			"data-testid": dataTestID,
			onBlur,
			value,
			formatSelectedValue,
			onValueChange,
			...props
		},
		forwardedRef,
	) => (
		<SelectPrimitive.Root
			value={value === null ? NULL_VALUE : value}
			onValueChange={(v) => {
				if (v === NULL_VALUE) {
					onValueChange?.(null);
				} else {
					onValueChange?.(v);
				}
			}}
			{...props}
		>
			<SelectPrimitive.Trigger
				disabled={disabled || readOnly}
				className={clsx(
					"form-field inline-flex h-12 w-full items-center gap-1 py-2 pl-4 pr-1",
					"focus:ring-primary focus:outline-none focus:ring-1",
					"text-body-sm",
					"radix-placeholder:text-neutral-500",
					// Bit of a hack to get the value to truncate
					// See: https://github.com/radix-ui/primitives/issues/1363
					"min-w-0 [&>span:first-child]:truncate",
					hasError && "form-field-error",
					className,
				)}
				ref={forwardedRef}
				data-testid={dataTestID}
				onBlur={onBlur}
				id={id}
			>
				<SelectPrimitive.Value placeholder={placeholder}>
					{formatSelectedValue ? formatSelectedValue(value) : undefined}
				</SelectPrimitive.Value>

				<SelectPrimitive.Icon
					className={clsx(
						"ml-auto text-2xl",
						disabled ? "text-neutral-400" : "text-neutral-600",
					)}
				>
					<IconDropdownIndicator />
				</SelectPrimitive.Icon>
			</SelectPrimitive.Trigger>

			<SelectPrimitive.Portal>
				<SelectPrimitive.Content className="z-select relative overflow-hidden rounded-md bg-white shadow">
					{/* <SelectPrimitive.ScrollUpButton className="flex h-4 w-full items-center justify-center text-[10px] leading-none">
						<IconSlimChevron direction="up" />
					</SelectPrimitive.ScrollUpButton> */}

					<SelectPrimitive.Viewport className="p-1">
						{children}
					</SelectPrimitive.Viewport>

					{/* <SelectPrimitive.ScrollDownButton className="flex h-4 w-full items-center justify-center text-[10px] leading-none">
						<IconSlimChevron direction="down" />
					</SelectPrimitive.ScrollDownButton> */}
				</SelectPrimitive.Content>
			</SelectPrimitive.Portal>
		</SelectPrimitive.Root>
	),
);

export const SelectItem = forwardRef(
	(
		{
			children,
			className,
			value,
			...props
		}: Omit<SelectPrimitive.SelectItemProps, "value"> & {
			value: string | null;
		},
		forwardedRef: React.ForwardedRef<HTMLDivElement>,
	) => (
		<SelectPrimitive.Item
			ref={forwardedRef}
			className={clsx(
				className,
				"text-body-sm relative my-1 flex h-8 select-none items-center rounded-sm px-3 first:mt-0 last:mb-0 hover:bg-neutral-100",
				"radix-highlighted:text-primary radix-highlighted:bg-neutral-100 radix-highlighted:outline-none",
				"radix-disabled:text-neutral-300 radix-disabled:pointer-events-none",
				"radix-state-checked:text-white radix-state-checked:bg-primary",
			)}
			value={value === null ? NULL_VALUE : value}
			{...props}
		>
			<SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
		</SelectPrimitive.Item>
	),
);

const SelectGroup = forwardRef<
	HTMLDivElement,
	SelectPrimitive.SelectGroupProps
>((props, forwardedRef) => (
	<SelectPrimitive.Group ref={forwardedRef} {...props} />
));

const SelectSeparator = forwardRef<
	HTMLDivElement,
	SelectPrimitive.SelectSeparatorProps
>(({ className, ...props }, forwardedRef) => (
	<SelectPrimitive.Separator
		ref={forwardedRef}
		className={clsx(className, "my-1 h-px bg-neutral-200")}
		{...props}
	/>
));

const SelectLabel = forwardRef<
	HTMLDivElement,
	SelectPrimitive.SelectLabelProps
>(({ className, ...props }, forwardedRef) => (
	<SelectPrimitive.Label
		className={clsx(
			className,
			"text-overline-sm flex items-center px-3 pt-2 pb-1 text-[10px] leading-none tracking-wider text-neutral-600",
		)}
		ref={forwardedRef}
		{...props}
	/>
));

type SelectSubComponents = {
	Item: typeof SelectItem;
	Group: typeof SelectGroup;
	Separator: typeof SelectSeparator;
	Label: typeof SelectLabel;
};

export type SelectFieldProps = SelectProps & FormFieldProps;

const SelectField = forwardRef<HTMLButtonElement, SelectFieldProps>(
	(
		{
			id,
			label,
			hint,
			error,
			tooltip,
			hasError,
			disabled,
			isLoading,
			...props
		},
		forwardedRef,
	) => (
		<FormField
			id={id}
			label={label}
			hint={hint}
			error={error}
			tooltip={tooltip}
			hasError={hasError}
			disabled={disabled}
			isLoading={isLoading}
		>
			<Select ref={forwardedRef} {...props} />
		</FormField>
	),
);

const SelectComponents: typeof Select & SelectSubComponents = Object.assign(
	Select,
	{
		Item: SelectItem,
		Group: SelectGroup,
		Separator: SelectSeparator,
		Label: SelectLabel,
	},
);

export { SelectComponents as Select };

const SelectFieldComponents: typeof SelectField & SelectSubComponents =
	Object.assign(SelectField, {
		Item: SelectItem,
		Group: SelectGroup,
		Separator: SelectSeparator,
		Label: SelectLabel,
	});

export { SelectFieldComponents as SelectField };
