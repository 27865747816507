/* eslint-disable */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelectCountriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SelectCountriesQuery = { __typename: 'Query', countries: Array<{ __typename: 'Country', id: string, name: string, isoAlpha2: string } | null> };


export const SelectCountriesDocument = gql`
    query SelectCountries {
  countries {
    id
    name
    isoAlpha2
  }
}
    `;

/**
 * __useSelectCountriesQuery__
 *
 * To run a query within a React component, call `useSelectCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectCountriesQuery(baseOptions?: Apollo.QueryHookOptions<SelectCountriesQuery, SelectCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectCountriesQuery, SelectCountriesQueryVariables>(SelectCountriesDocument, options);
      }
export function useSelectCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectCountriesQuery, SelectCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectCountriesQuery, SelectCountriesQueryVariables>(SelectCountriesDocument, options);
        }
export type SelectCountriesQueryHookResult = ReturnType<typeof useSelectCountriesQuery>;
export type SelectCountriesLazyQueryHookResult = ReturnType<typeof useSelectCountriesLazyQuery>;
export type SelectCountriesQueryResult = Apollo.QueryResult<SelectCountriesQuery, SelectCountriesQueryVariables>;