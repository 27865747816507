import React, { useEffect } from "react";
import { gql } from "@apollo/client";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { Link, IconSearch } from "@jasperlabs/jux-next";
import { DeepPartial } from "@jasperlabs/types";

import { Address, AutocompleteAddressInput, ManualAddressInput } from "types";
import { getNestedObjectByKey } from "components/utilities/getNestedObjectByKey";

import FormikInputField from "../form/FormikInputField";
import CountrySelectField from "../CountrySelectField";
import AddressAutoComplete from "./AddressAutoComplete";
import { AddressSearchFormValues } from "./types";

type Props = {
	groupKey: string;
	label: string;
	id?: string;
};

export const addressSearchFormSchema = Yup.object().shape({
	placeId: Yup.string()
		.nullable()
		.when("isUsingSearch", {
			is: true,
			then: (schema) => schema.required("Must select an address"),
		}),
	addressLine1: Yup.string()
		.nullable()
		.when("isUsingSearch", {
			is: false,
			then: (schema) => schema.required("Must enter address"),
		}),
	suburb: Yup.string().nullable(),
	city: Yup.string()
		.nullable()
		.when("isUsingSearch", {
			is: false,
			then: (schema) => schema.required("Must enter city"),
		}),
	postCode: Yup.string()
		.nullable()
		.when("isUsingSearch", {
			is: false,
			then: (schema) => schema.required("Must enter post code"),
		}),
	country: Yup.string()
		.nullable()
		.when("isUsingSearch", {
			is: false,
			then: (schema) => schema.required("Must enter country"),
		}),
});

export const addressSearchFormInitialValues: AddressSearchFormValues = {
	isUsingSearch: true,

	rawSearch: null,
	placeId: null,

	addressLine1: null,
	suburb: null,
	city: null,
	postCode: null,
	country: null,
};

export function makeAddressSearchFormInitialValues(
	address?: DeepPartial<Address> | null,
) {
	return {
		// Default to the search input when there is no initial value, otherwise
		// show the full detail
		isUsingSearch: !address,
		placeId: null,
		rawSearch: null,
		addressLine1: address?.addressLine1 ?? null,
		suburb: address?.suburb ?? null,
		city: address?.city ?? null,
		country: address?.country?.isoAlpha2 ?? null,
		postCode: address?.postCode ?? null,
	};
}

type AddressSearchFormInput = {
	autocompleteAddress?: AutocompleteAddressInput;
	manualAddress?: ManualAddressInput;
};

export function packAddressSearchFormValues(
	address: AddressSearchFormValues,
): AddressSearchFormInput {
	if (address.isUsingSearch) {
		return {
			autocompleteAddress: {
				placeId: address.placeId!,
				rawSearch: address.rawSearch!,
			},
		};
	}

	return {
		manualAddress: {
			addressLine1: address.addressLine1!,
			suburb: address.suburb,
			city: address.city!,
			country: address.country!,
			postCode: address.postCode!,
		},
	};
}

const AddressSearchForm = ({ groupKey, label, id }: Props) => {
	const { values, setFieldValue } = useFormikContext<{
		[groupKey: string]: AddressSearchFormValues;
	}>();

	const address = getNestedObjectByKey<AddressSearchFormValues>(
		values,
		groupKey,
	);

	const testID = id ? `place-of-birth-search-${id}` : "place-of-birth-search";

	const toggleSearch = () =>
		setFieldValue(`${groupKey}.isUsingSearch`, !address?.isUsingSearch);

	useEffect(() => {
		if (address?.isUsingSearch) {
			setFieldValue(`${groupKey}.addressLine1`, null);
			setFieldValue(`${groupKey}.suburb`, null);
			setFieldValue(`${groupKey}.city`, null);
			setFieldValue(`${groupKey}.postCode`, null);
			setFieldValue(`${groupKey}.country`, null);
		}
	}, [address?.isUsingSearch, setFieldValue, groupKey]);

	if (address?.isUsingSearch) {
		return (
			<div data-testid={testID}>
				<AddressAutoComplete
					groupKey={groupKey}
					label={label}
					noOptionsMessage={
						<p className="text-body-sm text-color-body text-center">
							Can&#39;t find your address?{" "}
							<Link
								as="button"
								className="inline"
								onClick={toggleSearch}
								data-testid="manual-entry-button"
							>
								Enter address manually
							</Link>
						</p>
					}
					hint={
						<div
							className="text-form-field-hint text-right"
							data-testid="manual-entry-button"
						>
							Can&#39;t find your address?{" "}
							<Link as="button" className="inline" onClick={toggleSearch}>
								Enter address manually
							</Link>
						</div>
					}
					onSearchError={toggleSearch}
				/>
			</div>
		);
	}

	return (
		<div className="grid grid-cols-2 gap-x-4" data-testid={testID}>
			<div className="col-span-2">
				<FormikInputField
					className="col-span-2"
					name={`${groupKey}.addressLine1`}
					label={label}
				/>
			</div>
			<FormikInputField
				className="col-span-2 md:col-span-1"
				name={`${groupKey}.suburb`}
				label="Suburb"
			/>
			<FormikInputField
				className="col-span-2 md:col-span-1"
				name={`${groupKey}.city`}
				label="City"
			/>
			<FormikInputField
				className="col-span-2 md:col-span-1"
				name={`${groupKey}.postCode`}
				label="Postcode"
			/>
			<CountrySelectField
				className="col-span-2 md:col-span-1"
				name={`${groupKey}.country`}
				label="Country"
				byProperty="isoAlpha2"
			/>
			<Link
				as="button"
				icon={<IconSearch fontSize={20} />}
				onClick={toggleSearch}
				className="col-span-2 text-sm mb-5"
			>
				Use address finder
			</Link>
		</div>
	);
};

AddressSearchForm.fragment = gql`
	fragment AddressSearchForm on Address {
		id
		placeId
		addressLine1
		suburb
		city
		postCode
		country {
			id
			isoAlpha2
		}
	}
`;

export { type AddressSearchFormValues } from "./types";

export default AddressSearchForm;
