import React, { forwardRef } from "react";
import clsx from "clsx";

import type {
	PolymorphicComponentPropsWithRef,
	PolymorphicRef,
} from "../../../types/polymorphic-component";
import { IconChevron } from "../../icon";

export enum LinkVariant {
	Primary = "primary",
	Secondary = "secondary",
	Tertiary = "tertiary",
	Neutral = "neutral",
	Success = "success",
	Danger = "danger",
	InvertedPrimary = "inverted-primary",
	InvertedSecondary = "inverted-secondary",
	None = "no-variant",
	AllWhite = "all-white",
	NavInverted = "nav",
	Black = "black",
}

export type LinkProps<C extends React.ElementType> =
	PolymorphicComponentPropsWithRef<
		C,
		{
			/**
			 * Display a chevron to the right of link.
			 * @default false
			 */
			hasChevron?: boolean;
			/**
			 * Disable the link
			 * @default false
			 */
			isDisabled?: boolean;
			/**
			 * The variant/color scheme of the button.
			 * @default LinkVariat.Primary
			 */
			variant?: LinkVariant;
			/**
			 * Display an icon to the left of the link.
			 * @default null
			 */
			icon?: React.ReactNode;
		}
	>;

type LinkComponent = <C extends React.ElementType = "a">(
	props: LinkProps<C>,
) => React.ReactNode;

export const Link: LinkComponent = forwardRef(
	<C extends React.ElementType = "a">(
		{
			as,
			children,
			className,
			isDisabled = false,
			hasChevron = false,
			variant = LinkVariant.Primary,
			icon,
			onClick,
			...props
		}: LinkProps<C>,
		ref?: PolymorphicRef<C>,
	) => {
		const Component = as ?? "a";
		const hasIcon = icon || hasChevron;
		return (
			<Component
				onClick={onClick}
				className={clsx(
					"group transition-colors duration-75 focus:underline focus:outline-none",
					onClick && "cursor-pointer",
					hasIcon && ["inline-flex items-center gap-2"],
					isDisabled && [
						"disabled-link pointer-events-none cursor-not-allowed",
					],
					variant === LinkVariant.Primary && [
						"text-link-primary hover:text-link-primary-hover focus:text-link-primary-focus active:text-link-primary-active disabled-link:text-link-primary-disabled",
					],
					variant === LinkVariant.Secondary && [
						"text-link-secondary hover:text-link-secondary-hover focus:text-link-secondary-focus active:text-link-secondary-active disabled-link:text-link-secondary-disabled",
					],
					variant === LinkVariant.Tertiary && [
						"text-link-tertiary hover:text-link-tertiary-hover focus:text-link-tertiary-focus active:text-link-tertiary-active disabled-link:text-link-tertiary-disabled",
					],
					variant === LinkVariant.Neutral && [
						"text-link-neutral hover:text-link-neutral-hover focus:text-link-neutral-focus active:text-link-neutral-active disabled-link:text-link-neutral-disabled",
					],
					variant === LinkVariant.Success && [
						"text-link-success hover:text-link-success-hover focus:text-link-success-focus active:text-link-success-active disabled-link:text-link-success-disabled",
					],
					variant === LinkVariant.Danger && [
						"text-link-danger hover:text-link-danger-hover focus:text-link-danger-focus active:text-link-danger-active disabled-link:text-link-danger-disabled",
					],
					variant === LinkVariant.InvertedPrimary && [
						"text-link-inverted-primary hover:text-link-inverted-primary-hover focus:text-link-inverted-primary-focus active:text-link-inverted-primary-active disabled-link:text-link-inverted-primary-disabled",
					],
					variant === LinkVariant.InvertedSecondary && [
						"text-link-inverted-secondary hover:text-link-inverted-secondary-hover focus:text-link-inverted-secondary-focus active:text-link-inverted-secondary-active disabled-link:text-link-inverted-secondary-disabled",
					],
					variant === LinkVariant.AllWhite && [
						"text-white hover:text-white/50 focus:text-white/50 active:text-white/50",
					],
					variant === LinkVariant.NavInverted && [
						"text-link-nav/80 hover:text-link-nav-hover focus:text-link-nav-focus active:text-link-nav-active nav-active:text-link-nav-active",
					],
					variant === LinkVariant.Black && [
						"text-link-black hover:text-link-black-hover focus:text-link-black-focus active:text-link-black-active disabled-link:text-link-black-disabled",
					],
					className,
				)}
				{...props}
				ref={ref}
			>
				{icon && <>{icon}</>}
				{children}
				{hasChevron && (
					<IconChevron className="group-disabled-link:translate-x-[0] -rotate-90 transform fill-current text-inherit transition-transform duration-200 group-hover:translate-x-[5px]" />
				)}
			</Component>
		);
	},
);
