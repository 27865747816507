import React, { useRef, useEffect } from "react";
import gql from "graphql-tag";
import { SelectField } from "@jasperlabs/jux-next";
import * as R from "ramda";

import { countryIds } from "constants/countries";
import {
	useSelectCountriesQuery,
	SelectCountriesQuery,
} from "./CountrySelectField.generated";

import FormikSelectField, {
	FormikSelectFieldProps,
} from "../form/FormikSelectField";

gql`
	query SelectCountries {
		countries {
			id
			name
			isoAlpha2
		}
	}
`;

type Props = FormikSelectFieldProps & {
	hasAutofocus?: boolean;
	byProperty?: "id" | "isoAlpha2";
	placeholder?: string;

	/**
	 * When true, will show a value of "None" which will be selected when the
	 * value is null.
	 *
	 * @default false
	 */
	nullable?: boolean;
};

type Country = NonNullable<SelectCountriesQuery["countries"][0]>;

const sortCountries = (countries: any) => {
	const nz = countries.find((country: any) => country.id === countryIds.NZ);
	const au = countries.find((country: any) => country.id === countryIds.AU);
	const us = countries.find((country: any) => country.id === countryIds.US);
	// @ts-ignore
	const filterSortMap = R.compose(
		R.when(() => au, R.prepend(au)),
		R.when(() => us, R.prepend(us)),
		R.when(() => nz, R.prepend(nz)),
		R.sortBy(R.prop("name")),
		R.without([nz, au, us]),
	);

	return filterSortMap(countries);
};

const CountrySelectField = ({
	name,
	id,
	label,
	hasAutofocus,
	byProperty = "id",
	placeholder,
	nullable = false,
	...props
}: Props) => {
	const selectRef = useRef(null);
	const shouldFocusSelect = useRef(hasAutofocus);

	const { data, loading } = useSelectCountriesQuery();

	const { countries = [] } = data || {};

	const sortedCountries = sortCountries(countries ?? []) as Country[];

	useEffect(() => {
		if (!loading && shouldFocusSelect.current) {
			// @ts-ignore
			selectRef.current?.focus();
			shouldFocusSelect.current = false;
		}
	}, [loading]);

	if (loading) {
		return (
			<SelectField
				label={label}
				id={id ?? name}
				readOnly
				value={null}
				className="animate-pulse"
			/>
		);
	}

	return (
		<FormikSelectField
			name={name}
			id={id}
			label={label}
			ref={selectRef}
			{...props}
		>
			{placeholder && (
				<SelectField.Item disabled value="">
					{placeholder}
				</SelectField.Item>
			)}
			<SelectField.Separator />
			{nullable && <SelectField.Item value={null}>None</SelectField.Item>}
			{sortedCountries.map((country) => (
				<>
					<SelectField.Item value={country[byProperty]} key={country.id}>
						{country.name}
					</SelectField.Item>
					{country.id === countryIds.NZ && <SelectField.Separator />}
				</>
			))}
		</FormikSelectField>
	);
};

export default CountrySelectField;
