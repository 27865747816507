import React from "react";

const SvgMarker = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<g clipPath="url(#Marker_svg__clip0)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.313 7.319A11.32 11.32 0 0 1 20.264 4a11.32 11.32 0 0 1 11.272 11.272c0 7.1-9.056 16.529-11.272 18.728C18.057 31.809 9 22.372 9 15.272a11.32 11.32 0 0 1 3.313-7.954zm4.428 13.217a6.34 6.34 0 1 0 7.046-10.541 6.34 6.34 0 0 0-7.046 10.54z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export default SvgMarker;
