import React, { forwardRef, useId } from "react";

import { SelectField, type SelectFieldProps } from "@jasperlabs/jux-next";
import { Field, type FieldProps } from "formik";

export type FormikSelectFieldProps = Omit<SelectFieldProps, "value" | "id"> & {
	id?: string;
	name: string;
};

const FormikSelectField = forwardRef<HTMLButtonElement, FormikSelectFieldProps>(
	({ name, onBlur, id, ...props }, forwardedRef) => {
		const generatedId = useId();

		return (
			<Field name={name}>
				{({ field, meta, form }: FieldProps) => {
					const isInvalid = Boolean(meta.touched && meta.error);

					const handleChange = (value: string | null) => {
						form.setFieldValue(name, value);
					};

					const handleBlur = (e: React.FocusEvent<HTMLButtonElement>) => {
						field.onBlur(name)(e);
						onBlur?.(e);
					};
					return (
						<SelectField
							name={field.name}
							id={id ?? generatedId}
							onValueChange={handleChange}
							onBlur={handleBlur}
							hasError={isInvalid}
							error={meta.error}
							{...props}
							ref={forwardedRef}
							value={field.value}
						/>
					);
				}}
			</Field>
		);
	},
);

export default FormikSelectField;
