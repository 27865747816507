import clsx from "clsx";
import React from "react";

type SvgChevronProps = React.SVGProps<SVGSVGElement> & {
	/**
	 * The direction the chevron should point.
	 * @default down
	 */
	direction?: "up" | "down" | "left" | "right";
	className?: string;
};

const SvgChevron = ({
	direction = "down",
	className,
	...props
}: SvgChevronProps) => (
	<svg
		className={clsx(
			className,
			direction === "down" && "rotate-0",
			direction === "left" && "rotate-90",
			direction === "up" && "rotate-180",
			direction === "right" && "-rotate-90",
		)}
		width="1em"
		height="1em"
		viewBox="0 0 40 40"
		fill="none"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.5 26.427L10.844 17 7 20.82 20.5 34 34 20.82 30.156 17 20.5 26.427z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgChevron;
