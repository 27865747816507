import React from "react";

const SvgSearch = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.5 7.273c-5.648 0-10.227 4.579-10.227 10.227 0 5.648 4.579 10.227 10.227 10.227 5.648 0 10.227-4.579 10.227-10.227 0-5.648-4.579-10.227-10.227-10.227zM5 17.5C5 10.596 10.596 5 17.5 5S30 10.596 30 17.5 24.404 30 17.5 30 5 24.404 5 17.5z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M32.348 35L23.5 26l1.5-1.5 9 8.848L32.348 35z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgSearch;
