import React, { ForwardedRef, forwardRef } from "react";
import clsx from "clsx";

import { FormField, FormFieldProps } from "../FormField";
import { composeValidators } from "./validators";

export type InputProps = React.ComponentPropsWithoutRef<"input"> & {
	disabled?: boolean;
	readOnly?: boolean;
	hasError?: boolean;
	iconRight?: React.ReactNode;
	iconLeft?: React.ReactNode;
	textStyle?: string;
	validators?: ((value?: string) => boolean)[];
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			id,
			type = "text",
			disabled = false,
			readOnly = false,
			hasError = false,
			iconLeft,
			iconRight,
			textStyle,
			className,
			validators = [],
			onChange,
			onBlur,
			...props
		},
		forwardedRef,
	) => {
		const isValid = composeValidators(...validators);

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (isValid(e.target.value)) {
				onChange?.(e);
			}
		};

		const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
			if (isValid(e.target.value)) {
				onBlur?.(e);
			}
		};

		return (
			<div className={clsx("relative w-full", className)}>
				{iconLeft && (
					<div
						className={clsx(
							disabled ? "text-form-field-disabled" : "text-form-field-icon",
							"absolute left-0 top-0 bottom-0 flex items-center pl-4 text-[20px]",
						)}
					>
						{iconLeft}
					</div>
				)}
				<input
					ref={forwardedRef}
					id={id}
					type={type}
					disabled={disabled}
					readOnly={readOnly}
					onChange={handleChange}
					onBlur={handleBlur}
					className={clsx(
						"form-field h-12 w-full px-4 py-2 focus:ring-0 text-lg",
						iconLeft && "pl-12",
						iconRight && "pr-12",
						hasError && "form-field-error",
						textStyle,
					)}
					{...props}
				/>
				{iconRight && (
					<div
						className={clsx(
							disabled
								? "text-form-field-icon-disabled"
								: "text-form-field-icon",
							"absolute right-0 top-0 bottom-0 flex items-center pr-4 text-[20px]",
						)}
					>
						{iconRight}
					</div>
				)}
			</div>
		);
	},
);

export type InputFieldProps = InputProps & FormFieldProps;

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
	(
		{ id, label, hint, hasError, error, tooltip, disabled, ...props },
		forwardedRef: ForwardedRef<HTMLInputElement>,
	) => (
		<FormField
			id={id}
			label={label}
			hint={hint}
			error={error}
			tooltip={tooltip}
			hasError={hasError}
			disabled={disabled}
		>
			<Input ref={forwardedRef} {...props} />
		</FormField>
	),
);
