export const composeValidators =
	(...fns: ((value?: string) => boolean)[]) =>
	(value?: string) =>
		[...fns].reverse().reduce((acc, fn) => acc && fn(value), true);

export const isLessThan = (upper: number) => (value?: number) =>
	upper > (value || 0);

export const isGreaterThan = (lower: number) => (value?: number) =>
	lower < (value || 0);

export const isLessThanOrEqual = (upper: number) => (value?: number) =>
	upper >= (value || 0);

export const isGreaterThanOrEqual = (lower: number) => (value?: number) =>
	lower <= (value || 0);

export const characterUpperLimit = (limit: number) => (value?: string) =>
	String(value).length <= limit;

const onlyDigitsRegularExpression = /^\d*$/;
export const onlyDigits = (value?: string) =>
	onlyDigitsRegularExpression.test(String(value));
