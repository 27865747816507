import React, { forwardRef } from "react";
import clsx from "clsx";
import { Combobox as ComboboxPrimitive } from "@headlessui/react";

import { IconCross, IconDropdownIndicator } from "../../icon";
import { Spinner, SpinnerVariant } from "../../progress";
import { FormField, FormFieldProps } from "../FormField";

// TODO: Types are a bit weird in here (around the `value` and `onChange`
// props). Something in headless-ui that I can't figure out.

export type ComboboxProps = {
	id?: string;
	className?: string;
	value?: any;
	displayValue?: any;
	placeholder?: string;

	/**
	 * Called when the user types in the Combobox's input.
	 * @param value The value of the input.
	 */
	onSearch?: (value: string) => void;

	/**
	 * Called when the user selects an option.
	 * @param value The value of the selected option.
	 */
	onChange?: (value: any) => void;

	/**
	 * A list of `Combobox.Option` components.
	 */
	children: React.ReactNode;

	/**
	 * The icon to use for the dropdown indicator.
	 * @default <IconDropdownIndicator />
	 */
	iconDropdownIndicator?: React.ReactNode;

	hasError?: boolean;
	disabled?: boolean;
	loading?: boolean;

	/**
	 * Whether multiple options can be selected or not.
	 * @default false
	 */
	multiple?: boolean;

	/**
	 * Whether you can clear the combobox or not.
	 * @default false
	 */
	nullable?: boolean;
};

const Combobox = forwardRef(
	(
		{
			children,
			value,
			displayValue,
			onChange,
			onSearch,
			id,
			placeholder,
			className,
			iconDropdownIndicator,
			multiple = false,
			hasError,
			loading,
			disabled,
			nullable,
			...props
		}: ComboboxProps,
		forwardedRef: React.ForwardedRef<HTMLDivElement>,
	) => {
		const handleChange = (val: any) => {
			onChange?.(val);
		};

		return (
			<ComboboxPrimitive
				as="div"
				className="relative w-full"
				// @ts-ignore headless-ui seems to expect this to only ever be true, not
				// sure why
				multiple={multiple}
				// @ts-ignore
				nullable={nullable}
				value={value}
				onChange={handleChange}
				ref={forwardedRef}
				disabled={disabled}
				{...props}
			>
				<div
					className={clsx(
						"form-field focus-within:outline-primary relative h-12 w-full overflow-hidden focus-within:outline focus-within:outline-1",
						hasError && "form-field-error",
						disabled && "form-field-disabled",
						className,
					)}
				>
					<ComboboxPrimitive.Input
						data-testid={`${id}-input`}
						id={id}
						className={clsx(
							"text-body-sm h-full w-full border-none py-2 pl-4 pr-10 focus:outline-none focus:ring-0",
							disabled && "bg-form-field-disabled",
							hasError && "bg-form-field-error",
						)}
						placeholder={placeholder}
						displayValue={displayValue}
						onChange={(e) => {
							onSearch?.(e.target.value);
						}}
					/>
					<ComboboxPrimitive.Button
						disabled={disabled}
						className="absolute inset-y-0 right-0 mr-2 flex items-center pr-1 text-2xl"
					>
						{/* @ts-ignore */}
						{(ctx) => {
							if (loading) {
								return <Spinner variant={SpinnerVariant.Neutral} size={20} />;
							}

							if (nullable && ctx.value) {
								return (
									<IconCross
										className="-m-1 text-[21px] text-neutral-400 hover:text-neutral-500"
										onClick={() => {
											onChange?.(null);
										}}
									/>
								);
							}

							return (
								iconDropdownIndicator ?? (
									<IconDropdownIndicator className="-m-1" />
								)
							);
						}}
					</ComboboxPrimitive.Button>
				</div>

				<ComboboxPrimitive.Options
					data-testid={`${id}-options`}
					className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white px-1 py-1 shadow"
				>
					{children}
				</ComboboxPrimitive.Options>
			</ComboboxPrimitive>
		);
	},
);

export type ComboboxOptionProps = {
	value: any;
	children?: React.ReactNode;
	disabled?: boolean;
	className?: string;
};

const ComboboxOption = forwardRef(
	(
		{ children, value, className, ...props }: ComboboxOptionProps,
		forwardedRef: React.ForwardedRef<HTMLLIElement>,
	) => (
		<ComboboxPrimitive.Option
			ref={forwardedRef}
			className={clsx(
				"text-body-sm min-h-10 relative my-1 flex select-none items-center rounded-sm py-1 px-3 text-xs first:mt-0 last:mb-0",
				"ui-active:text-primary ui-active:bg-neutral-100 ui-active:outline-none",
				"ui-selected:bg-primary ui-selected:text-white",
				"ui-disabled:text-neutral-300 ui-disabled:pointer-events-none",
				className,
			)}
			value={value}
			{...props}
		>
			{children}
		</ComboboxPrimitive.Option>
	),
);

type ComboboxOptionsPlaceholderProps = React.ComponentPropsWithRef<"div"> & {
	children?: React.ReactNode;
	className?: string;
};

const ComboboxOptionsPlaceholder = forwardRef(
	(
		{ children, className, ...props }: ComboboxOptionsPlaceholderProps,
		forwardedRef: React.ForwardedRef<HTMLDivElement>,
	) => (
		<div
			ref={forwardedRef}
			className={clsx(
				"text-body-sm flex h-24 items-center justify-center text-neutral-700",
				className,
			)}
			{...props}
		>
			{children}
		</div>
	),
);

export type ComboboxFieldProps = FormFieldProps & ComboboxProps;

const ComboboxField = forwardRef(
	(
		{
			id,
			disabled,
			hasError,
			error,
			hint,
			label,
			className,
			tooltip,
			...props
		}: ComboboxFieldProps,
		forwardedRef: React.ForwardedRef<HTMLDivElement>,
	) => (
		<FormField
			id={id}
			disabled={disabled}
			hasError={hasError}
			error={error}
			hint={hint}
			label={label}
			className={className}
			tooltip={tooltip}
		>
			<Combobox id={id} ref={forwardedRef} {...props} />
		</FormField>
	),
);

const ComboboxComponents = Object.assign(Combobox, {
	Option: ComboboxOption,
	OptionsPlaceholder: ComboboxOptionsPlaceholder,
});

export { ComboboxComponents as Combobox };

const ComboboxFieldComponents = Object.assign(ComboboxField, {
	Option: ComboboxOption,
	OptionsPlaceholder: ComboboxOptionsPlaceholder,
});

export { ComboboxFieldComponents as ComboboxField };
